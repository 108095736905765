import React, { Component } from "react";
import ReactRoundedImage from "react-rounded-image";
import FolsomLogo from "../../assets/img/FolsomLogo.png";
import SunriseLogo from "../../assets/img/SunriseLogo.png";
import defaultLogo from "../../assets/img/brand/men.jpg";

import MartInc from "../../assets/img/TwmLogo.png";
import { Col, Row, Container, FormGroup, Input, Label } from "reactstrap";
import { baseurl } from "../../baseurl.js";
import { headers, companyId, username, token } from "../../utils/variables";
import { Redirect } from "react-router-dom";
import "./Print.css";
import axios from "axios";
import moment from "moment";

import Responseloader from "./ShortCutComponents/ResponseLoader";

var thisContex;
var UserName = "ishtiyak_master"; //localStorage.getItem("firstName");
var companylog = localStorage.getItem("profile_pic");
let companySym;

/////////////////////////////-------------------ClassComponents Start Here----------///////////////////////////
class PrintView extends Component {
  constructor(props) {
    super(props);

    let tempLogedIn = true;
    //////////////------------Token for Login prepose True and False Condition----------///////////////////////

    let propInvoiceId = this.props.location.state?.id;
    let usernameUrl = -1;
    let profilePicUrl = -1;
    let companyIdUrl = -1;
    propInvoiceId = propInvoiceId ? propInvoiceId : -1;
    // console.log("this.prop", this.props);
    // alert(propInvoiceId);
    if (!(propInvoiceId > 0)) {
      // const { id, username } = this.props.match.params;
      // alert("id= id", id);
      // const { id, username } = useParams();

      propInvoiceId = this.props?.match?.url;
      const completeUrl = this.props?.match?.url;
      // alert(propInvoiceId);
      // console.log(propInvoiceId);
      propInvoiceId = propInvoiceId.match(/\/emailInvoiceData\/:(\d+)/);
      // console.log(propInvoiceId);
      propInvoiceId = propInvoiceId ? propInvoiceId[1] : null;
      usernameUrl = completeUrl.match(/username=([^&]+)/);
      usernameUrl = usernameUrl ? usernameUrl[1] : null;
      profilePicUrl = completeUrl.match(/profile_pic=([^&]+)/);
      profilePicUrl = profilePicUrl ? profilePicUrl[1] : null;
      companyIdUrl = completeUrl.match(/companyId=([^&]+)/);
      companyIdUrl = companyIdUrl ? companyIdUrl[1] : null;
      UserName =
        usernameUrl === "ishtiyak_master4@gmail.com" ? "ishtiyak_master" : null;
      // usernameUrl =
      // const params = new URLSearchParams(propInvoiceId);
      // console.log(params);
      // const id = params.get("id");

      // propInvoiceId = id || -1;
      // propInvoiceId = propInvoiceId.substring(1);
      //  alert(propInvoiceId + " " + typeof propInvoiceId);
    }

    // console.log(propInvoiceId);
    // alert("in" + propInvoiceId);
    //////////////-------Components this States here to use in only Class Components ------///////////////////////
    this.state = {
      logInStatus: tempLogedIn,
      invoiceNo: "",
      poNumber: "",
      isMobile: window.innerWidth,
      issueDate: "",
      dueDate: "",
      companyName: "",
      clientName: "",
      viYearMake: "",
      zipCode: "",
      viModel: "",
      licenseNo: "",
      ExpenseDesc: "",
      vehicleIdNo: "",
      viMileage: "",
      city: "",
      tireFee: "",
      state: "",
      loadingResponce: false,
      labour: "",
      TireFee: "",
      TireDispossal: "",
      total_fet: 0,
      zip: "",
      disTotalItems: -1,
      sub_Amount_total_items: -1,
      total_Amount: -1,
      invoiceId: propInvoiceId,
      downloadLink: null,
      usernameUrl,
      companyIdUrl,
      isMobile: window.innerWidth,
    };
    thisContex = this;
    companySym = companylog
      ? `${baseurl}/getImages/labelCard/?fileName=${companylog}`
      : profilePicUrl;
  }
  componentDidMount() {
    this.getPrintCall();
  }
  downloadInvoice() {
    const element = document.getElementById("pdfContent");
  }
  dueFormator(cell, row, enumObject, index) {
    let newCell = "";
    if (typeof cell === "string") {
      cell = parseFloat(cell);
    }
    newCell = cell?.toLocaleString("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    return newCell;
  }
  convertToCurrency(param_number) {
    return param_number !== 0 && param_number !== null && param_number > 0
      ? Number(
          param_number?.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",")
        ).toFixed(2)
      : 0;
  }
  getPrintCall() {
    let invLable;

    // axios
    //   .post(`${baseurl}/auth/getCompany`, {
    //     username: username,
    //   })
    //   .then((response) => {
    //     var data = response.data.data;

    //     for (let index = 0; index < data.length; index++) {
    //       //  console.log('data :  >> ', data  , data[index].COMPANY_ID , data[index].LABEL , data[index].COMPANY_ID == companyId);
    //       if (data[index]?.COMPANY_ID == companyId)
    //         invLable = data[index]?.LABEL;
    //     }
    //     console.log("invLable......", invLable, companylog);
    //     companySym = `${baseurl}/getImages/labelCard/?fileName=${invLable}`;
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    const { state } = this.props?.location;
    // alert("calll");
    // let invoiceId = 7;
    if (this.state.invoiceId !== -1) {
      thisContex.setState({ loadingResponce: true });

      if (state?.printType === "Purchase") {
        axios
          .post(
            `${baseurl}/Support/getPrintPurchaseView`,
            {
              username,
              invoiceId: state?.id,
              companyId,
            },
            headers
          )
          .then(function (response) {
            let res_mt = response.data.data[0];
            let res_det = response.data.data1;
            let disTotalItems = 0;
            let sub_Amount_total_items = 0;
            let subTotalTaxAble = 0;
            let subTotalNonTaxAble = 0;
            let total_Amount = 0;
            let invoiceNo = res_mt["DOCUMENT_NO"];
            let invBookId = res_mt["INV_BOOK_ID"];
            let postedYn = res_mt["posted_yn"];
            let labour = res_mt["LABOUR"];
            let TireDispossal = res_mt["total_Tire_Disposal"];
            let total_fet = res_mt["total_fet"];
            let tireFee = res_mt["totalTireFee"];
            let poNumber = res_mt["INVOICE_REF_NO"];
            let issueDate = res_mt["INVOICE_DATE"];
            let dueDate = res_mt["DUE_DATE"];
            let year_make = res_mt["year_make"];
            let viModel = res_mt?.model === "null" ? "" : res_mt?.model || "";
            let licenseNo = res_mt["license_no"];
            let remarks = res_mt["REMARKS"];
            let viMileage = res_mt["miles"];
            let city = res_mt["customer_city"];
            let vehicleIdNo = res_mt["vehicle_id_no"];
            let companyName = res_mt["Company_Name"];
            let clientName = res_mt["Client_Name"];
            let saleTaxAmount = res_det[0]["staxAmount"];
            let salesTax = res_det[0]["salesTax"];
            let ExpenseDesc = res_det[0]["Expense_Desc"];
            let Item_Desc_Lov = res_det[0]["Item_Desc_Lov"];
            total_Amount = res_det[0]["AMOUNT"];
            let address = res_mt["supplierAdress"];
            let email = res_mt["email"];
            let phone = res_mt["phone"];
            let CURRENCY_DESC = res_mt["CURRENCY_DESC"];
            let company_no = res_mt["company_no"];
            let dynamicInvMessage = "Record";
            let state = res_mt["state_label"];
            let specificMsg = "";

            for (let index = 0; index < res_det.length; index++) {
              let element = res_det[index];
              disTotalItems += res_det[index].TC_DISCOUNT;
              sub_Amount_total_items += res_det[index].TC_AMOUNT;
              subTotalTaxAble +=
                element.service_item_yn === 1 ? 0 : element.TC_AMOUNT;
              subTotalNonTaxAble +=
                element.service_item_yn === 1 ? element.TC_AMOUNT : 0;
            }
            // sub_Amount_total_items = sub_Amount_total_items - disTotalItems;
            if (UserName === "ishtiyak_master") {
              // sub_Amount_total_items = sub_Amount_total_items + labour;
            }
            if (UserName === "ishtiyak_master") {
              total_Amount =
                total_Amount + TireDispossal + tireFee + labour + total_fet;
            }

            // console.log(res_det);
            if (invBookId === 1) {
              if (postedYn === 0) {
                dynamicInvMessage = "Invoice ( Draft )";
                specificMsg = "Invoice";
              } else if (postedYn == 1) {
                dynamicInvMessage = "Invoice";
              }
            } else if (invBookId === 2) {
              dynamicInvMessage = "Credit Note";
            } else if (invBookId === 4) {
              dynamicInvMessage = "Recurring";
            } else if (invBookId === 5) {
              dynamicInvMessage = "Subsistence";
            } else if (invBookId === 6) {
              dynamicInvMessage = "Purchase";
            } else if (invBookId === 7) {
              dynamicInvMessage = "Purchase Return";
            } else if (invBookId === 6) {
              dynamicInvMessage = "Purchase";
            } else if (invBookId === 9) {
              dynamicInvMessage = "ESTIMATE";
            } else if (invBookId === 10) {
              dynamicInvMessage = "Receipt";
            } else if (invBookId === 11) {
              dynamicInvMessage = "Journal Voucher";
            } else if (invBookId === 13) {
              dynamicInvMessage = "Customer Refund";
            } else if (invBookId === 14) {
              dynamicInvMessage = "Supplier Refund";
            } else if (invBookId === 22) {
              dynamicInvMessage = "Mileage";
            } else if (invBookId === 24) {
              dynamicInvMessage = "Supply Payments";
            } else if (invBookId === 25) {
              dynamicInvMessage = "Employee Payments";
            } else if (invBookId === 26) {
              dynamicInvMessage = "Purchase Recurring";
            }

            thisContex.setState({
              loadingResponce: false,
              invoiceNo: invoiceNo,
              poNumber: poNumber,
              issueDate: moment(issueDate).format("DD MMMM YYYY"),
              dueDate: moment(dueDate).format("DD MMMM YYYY"),
              companyName: companyName,
              clientName: clientName,
              res_det: res_det,
              ExpenseDesc,
              Item_Desc_Lov,
              disTotalItems,
              sub_Amount_total_items,
              subTotalNonTaxAble,
              subTotalTaxAble,
              total_Amount: total_Amount,
              saleTaxAmount,
              salesTax,
              viYearMake: year_make,
              zipCode: res_mt?.zipCode,
              address,
              labour,
              TireDispossal,
              total_fet,
              viModel,
              tireFee,
              licenseNo,
              remarks,
              vehicleIdNo,
              viMileage,
              email,
              city,
              phone,
              company_no,
              CURRENCY_DESC,
              invBookId,
              dynamicInvMessage,
              specificMsg,
              state,
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        // console.log("in22" + this.state.invoiceId);
        axios
          .post(
            `${baseurl}/Support/getPrintInvoiceView`,
            {
              username: username || this.state.usernameUrl,
              invoiceId: this.state.invoiceId,
              companyId: companyId || this.state.companyIdUrl,
            },

            headers
          )
          .then(function (response) {
            // return
            // alert("if h");

            let res_mt = response.data.data[0];
            let res_det = response.data.data1;
            let disTotalItems = 0;
            let sub_Amount_total_items = 0;
            let subTotalTaxAble = 0;
            let subTotalNonTaxAble = 0;
            let total_Amount = 0;

            let invoiceNo = res_mt?.["DOCUMENT_NO"];
            let paid_amt = res_mt?.["paid_amt"];
            let invBookId = res_mt?.["INV_BOOK_ID"];
            let postedYn = res_mt?.["posted_yn"];
            let labour = res_mt?.["LABOUR"];
            let TireDispossal = res_mt?.["total_Tire_Disposal"];
            let total_fet = res_mt?.["total_fet"];
            let tireFee = res_mt?.["totalTireFee"];
            let CC_Fee = res_mt?.["CC_Fee"];
            let ccFeePercentage =
              res_mt?.["pay_type_mt_percentage"]?.split("-")[2];
            let ccFeeDesc = res_mt?.["pay_type_mt_percentage"]?.split("-")[1];
            let ccFeeId = res_mt?.["pay_type_mt_percentage"]?.split("-")[0];
            let poNumber = res_mt?.["INVOICE_REF_NO"];
            let issueDate = res_mt?.["INVOICE_DATE"];
            let dueDate = res_mt?.["DUE_DATE"];
            let year_make =
              res_mt?.year_make === "null" ? "" : res_mt?.year_make || "";
            let viModel = res_mt?.model === "null" ? "" : res_mt?.model || "";
            let licenseNo =
              res_mt?.plate_no === "null" ? "" : res_mt?.plate_no || "";
            let remarks = res_mt?.["REMARKS"];
            let viMileage = res_mt?.["miles"];
            let saleCategory = res_mt?.["saleCategory"];
            let city = res_mt?.["customer_city"];
            let vehicleIdNo = res_mt?.["vehicle_id_no"];
            let companyName = res_mt?.["Company_Name"];
            let clientName = res_mt?.["Client_Name"];
            let saleTaxAmount = res_det[0]?.["staxAmount"];
            let salesTax = res_det[0]?.["salesTax"];
            total_Amount = res_det[0]?.["AMOUNT"];
            let address = res_mt?.["customer_address"];
            let email = res_mt?.["email"];
            let phone = res_mt?.["customer_phone_no"];
            let CURRENCY_DESC = res_mt?.["CURRENCY_DESC"];
            let company_no = res_mt?.["company_no"];
            let dynamicInvMessage = "Record";
            let state = res_mt?.["state_label"];
            let complete_address = res_mt?.["complete_address"];
            let company_fax_no = res_mt?.["company_fax_no"];
            let company_tel_no = res_mt?.["company_tel_no"];
            let company_email = res_mt?.["company_email"];
            let specificMsg = "";

            for (let index = 0; index < res_det.length; index++) {
              let element = res_det[index];
              disTotalItems += res_det[index].TC_DISCOUNT;
              sub_Amount_total_items += res_det[index].TC_AMOUNT;
              subTotalTaxAble += element.service_item_yn
                ? 0
                : element.TC_AMOUNT - element.TC_DISCOUNT;
              subTotalNonTaxAble += element.service_item_yn
                ? element.TC_AMOUNT - element.TC_DISCOUNT
                : 0;
              if (!salesTax && element.salesTax) {
                salesTax = element.salesTax;
              }

              // subTotalTaxAble += element.service_item_yn === 1 ? 0 : element.TC_AMOUNT - element.TC_DISCOUNT;
              // subTotalNonTaxAble  += element.service_item_yn === 1 ? element.TC_AMOUNT - element.TC_DISCOUNT : 0;
            }
            sub_Amount_total_items = sub_Amount_total_items - disTotalItems;
            if (UserName === "ishtiyak_master") {
              // sub_Amount_total_items = sub_Amount_total_items + labour;
            }
            if (UserName === "ishtiyak_master") {
              total_Amount = total_Amount + TireDispossal + tireFee + labour;
            }

            // console.log(res_det);
            if (invBookId === 1) {
              if (postedYn === 0) {
                dynamicInvMessage = "Invoice ( Draft )";
                specificMsg = "Invoice";
              } else if (postedYn == 1) {
                dynamicInvMessage = "Invoice";
              }
            } else if (invBookId === 2) dynamicInvMessage = "Credit Note";
            else if (invBookId === 4) dynamicInvMessage = "Recurring";
            else if (invBookId === 5) {
              dynamicInvMessage = "Subsistence";
            } else if (invBookId === 6) {
              dynamicInvMessage = "Purchase";
            } else if (invBookId === 7) {
              dynamicInvMessage = "Purchase Return";
            } else if (invBookId === 6) {
              dynamicInvMessage = "Purchase";
            } else if (invBookId === 9) {
              dynamicInvMessage = "ESTIMATE";
            } else if (invBookId === 10) {
              dynamicInvMessage = "Receipt";
            } else if (invBookId === 11) {
              dynamicInvMessage = "Journal Voucher";
            } else if (invBookId === 13) {
              dynamicInvMessage = "Customer Refund";
            } else if (invBookId === 14) {
              dynamicInvMessage = "Supplier Refund";
            } else if (invBookId === 22) {
              dynamicInvMessage = "Mileage";
            } else if (invBookId === 24) {
              dynamicInvMessage = "Supply Payments";
            } else if (invBookId === 25) {
              dynamicInvMessage = "Reimburse Employee";
            } else if (invBookId === 26) {
              dynamicInvMessage = "Purchase Recurring";
            }
            // alert(ccFeePercentage);
            console.log("ccFeeId== ", ccFeeId, typeof ccFeeId);
            const dynamicNamePrinting =
              username === "ishtiyak_master4@gmail.com"
                ? "Folsom Tire and Wheels"
                : username === "sunrisetires@yahoo.com"
                ? "Sunrise Tire and Wheels"
                : username === "t&wmart@yahoo.com"
                ? "Tire & Wheel Mart Inc"
                : "Company";
            thisContex.setState({
              loadingResponce: false,
              invoiceNo: invoiceNo,
              poNumber: poNumber,
              paid_amt,
              issueDate: moment(issueDate).format("DD MMMM YYYY"),
              dueDate: moment(dueDate).format("DD MMMM YYYY"),
              companyName: companyName,
              clientName: clientName,
              res_det: res_det,
              disTotalItems,
              sub_Amount_total_items,
              subTotalTaxAble,
              subTotalNonTaxAble,
              total_Amount: total_Amount,
              saleTaxAmount,
              salesTax,
              viYearMake: year_make,
              zipCode: res_mt?.zipCode,
              address,
              labour,
              TireDispossal,
              total_fet,
              viModel,
              tireFee,
              licenseNo,
              remarks,
              vehicleIdNo,
              viMileage,
              saleCategory,
              email,
              city,
              phone,
              company_no,
              CURRENCY_DESC,
              invBookId,
              dynamicInvMessage,
              specificMsg,
              state,
              logInStatus: thisContex.state.invoiceId > 0 ? true : false,
              CC_Fee,
              ccFeePercentage,
              ccFeeDesc,
              ccFeeId,
              complete_address,
              company_fax_no,
              company_tel_no,
              company_email,
              dynamicNamePrinting,
            });
          })
          .catch(function (error) {
            // alert("else h");
            thisContex.setState({
              logInStatus: false,
            });
            console.log(error);
          });
      }
    } else {
      this.setState({
        goBack: true,
      });
    }
  }
  goBackToInvoice() {
    this.setState({
      goBack: true,
    });
  }

  render() {
    const {
      invoiceId,
      logInStatus,
      invoiceNo,
      poNumber,
      issueDate,
      dueDate,
      companyName,
      clientName,
      disTotalItems,
      sub_Amount_total_items,
      subTotalNonTaxAble,
      subTotalTaxAble,
      total_Amount,
      saleTaxAmount,
      salesTax,
      phone,
      email,
      // ExpenseDesc,
      Item_Desc_Lov,
      tireFee,
      // viMake,
      viMileage,
      saleCategory,
      TireDispossal,
      total_fet,
      viModel,
      // viYear,
      viYearMake,
      zipCode,
      licenseNo,
      isMobile,
      remarks,
      vehicleIdNo,
      city,
      labour,
      state,
      // zip,
      company_no,
      address,
      goBack,
      CURRENCY_DESC,
      dynamicInvMessage,
      loadingResponce,
      specificMsg,
      invBookId,
      CC_Fee,
      paid_amt,
      ccFeePercentage,
      ccFeeId,
      complete_address,
      company_fax_no,
      company_tel_no,
      company_email,
      dynamicNamePrinting,
    } = this.state;
    if (!logInStatus) return <Redirect to="/login" />;
    else if (loadingResponce) {
      return <Responseloader />;
    } else if (goBack) {
      return (
        <Redirect
          // to="/CreativeInvoice"
          push
          to={{
            pathname: this.props.location.state?.url,
            state: {
              id: this.state.invoiceId,
              updateCheck: this.props.location.state?.updateCheck,
              invBookId: this.state.invBookId,
            },
          }}
        />
      );
    } else if (invoiceId !== -1) {
      return (
        <div id="pdfContent">
          {UserName === "ishtiyak_master" ? (
            <Row className="app-body-Invoice bg-white rounded-lg ">
              {isMobile >= 768 ? (
                // desktop view print code start here
                <Col xs="12">
                  <div id="pdfContent">
                    <br></br>
                    <br></br>
                    <Container>
                      <Row className="" style={{ marginTop: "-1.8rem" }}>
                        <Col xs="6">
                          <img
                            src={
                              username === "ishtiyak_master4@gmail.com"
                                ? FolsomLogo
                                : username === "sunrisetires@yahoo.com"
                                ? SunriseLogo
                                : username === "abid9162003@yahoo.com"
                                ? MartInc
                                : defaultLogo
                            }
                            alt="FOLSOM TIRE & WHEEL"
                            width={360}
                            height={120}
                          />
                        </Col>

                        <Col xs="6">
                          <div style={{ textAlign: "end" }}>
                            <button
                              className="btn btn-primary mx-2 btn-sm"
                              id="printPageButton"
                              onClick={() => {
                                window.print();
                              }}
                            >
                              Print to PDF!
                            </button>
                            <button
                              className="btn btn-primary m-1  btn-sm"
                              id="printPageButton"
                              onClick={() => {
                                this.goBackToInvoice();
                              }}
                            >
                              Back
                            </button>
                          </div>
                          <h3 className="text-right pr-2 my-0">
                            {invBookId === 1 ? "INVOICE: " : "Estimate: "}
                            <span className="text-muted"> {invoiceNo}</span>
                          </h3>
                          <hr className="border my-0  w-50 float-right " />
                          <br />
                          <h6 className="text-right ">
                            <b>
                              {complete_address} <br />
                              Tel: {company_tel_no} • Fax: {company_fax_no}{" "}
                              <br />
                              {company_email}
                            </b>{" "}
                          </h6>
                        </Col>
                      </Row>
                    </Container>

                    <Row className="my-1">
                      <Container>
                        <Row>
                          <Col xs="9" lg="9" md="9" className="border">
                            <h5>
                              Date :{" "}
                              <span className="h5 text-muted">
                                {moment(issueDate).format("MM-DD-YYYY")}
                              </span>
                            </h5>
                            {/* <p style={{ textAlign: "right" }}>
                           Due Date : {dueDate}
                         </p> */}
                          </Col>

                          <Col xs="3" lg="3" md="3" className="border">
                            <h5>
                              VIN :{" "}
                              <span className="h5 text-muted">
                                {vehicleIdNo === "null"
                                  ? ""
                                  : vehicleIdNo || ""}
                              </span>
                            </h5>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6" lg="6" className="border">
                            {" "}
                            <h5>
                              Name :{" "}
                              <span className="h5 text-muted">
                                {clientName}
                              </span>
                            </h5>
                          </Col>
                          <Col xs="3" lg="3" className="border">
                            {" "}
                            <h5>
                              Phone :{" "}
                              <span className="h5 text-muted">{phone}</span>
                            </h5>{" "}
                          </Col>

                          <Col md="3" xs="3" lg="3" className="border">
                            <h5>
                              Mileage :{" "}
                              <span className="h5 text-muted">{viMileage}</span>
                            </h5>
                          </Col>
                          <Col xs="9" lg="9" md="9" className="border">
                            <h5>
                              Address :{" "}
                              <span className="h5 text-muted">{address}</span>
                            </h5>
                          </Col>
                          <Col xs="3" lg="3" md="3" className="border">
                            <h5>
                              Type :{" "}
                              <span className="h5 text-muted">
                                {saleCategory}
                              </span>
                            </h5>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="4" lg="4" md="4" className="border">
                            <h5>
                              City :{" "}
                              <span className="h5 text-muted">{city}</span>
                            </h5>
                          </Col>
                          <Col xs="2" lg="2" md="2" className="border">
                            <h5>
                              State :{" "}
                              <span className="h5 text-muted">{state}</span>
                            </h5>
                          </Col>
                          <Col xs="3" lg="3" md="3" className="border">
                            <h5>
                              Zip :{" "}
                              <span className="h5 text-muted">{zipCode}</span>
                            </h5>
                          </Col>
                          <Col xs="3" lg="3" md="3" className="border">
                            <h5>
                              Model :{" "}
                              <span className="h5 text-muted">{viModel}</span>
                            </h5>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            xs="9"
                            lg="9"
                            md="9"
                            className="border-left pt-2"
                          >
                            <Row>
                              <div className="col-6 col-md-6">
                                {" "}
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    defaultChecked={ccFeeId === "1"}
                                    disabled
                                  />{" "}
                                  <Label
                                    style={{
                                      color: ccFeeId === "1" ? "red" : "",
                                    }}
                                    check
                                  >
                                    CASH
                                  </Label>
                                </FormGroup>
                              </div>
                              <div className="col-6 col-md-6">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    defaultChecked={ccFeeId === "2"}
                                    disabled
                                  />{" "}
                                  <Label
                                    style={{
                                      color: ccFeeId === "2" ? "red" : "",
                                    }}
                                    check
                                  >
                                    CHARGE
                                  </Label>
                                </FormGroup>
                              </div>
                            </Row>
                          </Col>
                          <Col xs="3" lg="3" md="3" className="border">
                            <h5>
                              License Plate No :{" "}
                              <span className="text-muted"> {licenseNo}</span>
                            </h5>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            md="9"
                            className="border-left border-bottom py-2"
                          >
                            <Row>
                              <div className="col-6 col-md-6">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    defaultChecked={ccFeeId === "3"}
                                    disabled
                                  />{" "}
                                  <Label
                                    style={{
                                      color: ccFeeId === "3" ? "red" : "",
                                    }}
                                    check
                                  >
                                    CHECK
                                  </Label>
                                </FormGroup>
                              </div>
                              <div className="col-6 col-md-6">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    defaultChecked={ccFeeId === "4"}
                                    disabled
                                  />{" "}
                                  <Label
                                    style={{
                                      color: ccFeeId === "4" ? "red" : "",
                                    }}
                                    check
                                  >
                                    FINANCE
                                  </Label>
                                </FormGroup>
                              </div>
                            </Row>
                          </Col>
                          <Col md="3" className="border">
                            <h5>
                              Year-Make :{" "}
                              <span className="h5 text-muted">
                                {viYearMake}
                              </span>
                            </h5>
                          </Col>
                        </Row>
                        {remarks !== "undefined" && remarks && (
                          <Row>
                            <Col md="12" className="border">
                              <h6>
                                {/* Notes visible to the client on the invoice:{" "} */}
                                Remarks :
                                <span className="text-muted"> {remarks}</span>
                              </h6>
                            </Col>
                          </Row>
                        )}
                      </Container>
                    </Row>
                    <Row className="my-1">
                      <Container className="border" style={{ height: "28rem" }}>
                        <div className="row">
                          {/* <Col md="1" className="border">
                           <h4>Code </h4>
                         </Col> */}
                          <div className=" col-1   border text-right">
                            <h6>Qty</h6>
                          </div>
                          <div className="col-2 border">
                            <h6>Part #</h6>{" "}
                          </div>
                          <div className="col-3 border">
                            <h6>Item</h6>{" "}
                          </div>
                          <div className="col-3   border">
                            <h6>Description</h6>
                          </div>
                          <div className=" col-1   border text-right">
                            <h6>Price</h6>
                          </div>
                          <div className=" col-1  border text-right px-1">
                            <h6>Discount</h6>
                          </div>
                          <div className=" col-1   border">
                            <h6 className="text-right">Net Total</h6>
                          </div>
                        </div>
                        <div className=" px-3 mx-n3">
                          {this.state.res_det?.map((itm, index) => {
                            return (
                              <div
                                className="d-flex flex-row border-bottom "
                                key={index}
                              >
                                {/* <Col md="1" className="border text-center">
                                 <p>{itm.ITEM_CODE} </p>
                               </Col> */}
                                <div className="col-1">
                                  <p className="mb-0   ">
                                    {itm.PRIMARY_QTY.toString()}
                                  </p>
                                </div>
                                <div className="col-2">
                                  <p className="mb-0">{itm.ITEM_CODE}</p>
                                </div>
                                <div className="col-3 ">
                                  {console.log(
                                    "jjj",
                                    itm?.mfg_part_no,
                                    typeof itm?.mfg_part_no,
                                    itm?.mfg_part_no == "null",
                                    !itm?.mfg_part_no,
                                    itm?.mfg_part_no == "null" ||
                                      !itm?.mfg_part_no
                                  )}
                                  <p className="mb-0">
                                    {itm?.mfg_part_no == "null" ||
                                    !itm?.mfg_part_no
                                      ? ""
                                      : ` ${itm.mfg_part_no}`}
                                    {itm.Item_Desc_Lov}
                                  </p>
                                </div>
                                <div className="col-3 ">
                                  <p className="mb-0">{itm.ITEM_DESC}</p>
                                </div>
                                <div className="px-1 col-1">
                                  <p className="mb-0 text-right">
                                    {CURRENCY_DESC}{" "}
                                    {(itm.TC_AMOUNT / itm.PRIMARY_QTY)
                                      .toFixed(2)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </p>
                                </div>
                                <div className="px-1 col-1">
                                  <p className="mb-0 text-right">
                                    {CURRENCY_DESC}{" "}
                                    {itm.TC_DISCOUNT.toFixed(2)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </p>
                                </div>
                                <div className="px-1 col-1">
                                  <p className="mb-0 text-right">
                                    {CURRENCY_DESC}{" "}
                                    {(itm.TC_AMOUNT - itm.TC_DISCOUNT)
                                      .toFixed(2)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                          <div className="d-flex flex-row  ">
                            <div className="col-9">
                              {/* <p className="mb-0   ">
                               Note : *Star marked items are taxable
                             </p> */}
                            </div>
                            <div className="col-1 px-1  border">
                              <p className="mb-0 text-right">Sub-Total</p>
                            </div>
                            <div className="col-1 px-1  border">
                              <p className="mb-0 text-right">
                                {" "}
                                {CURRENCY_DESC}{" "}
                                {disTotalItems
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </p>
                            </div>
                            <div className=" col-1 px-1  border">
                              <p className="mb-0 text-right">
                                {CURRENCY_DESC}{" "}
                                {sub_Amount_total_items
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Container>
                    </Row>
                    <Container className="border">
                      <div className="col-12 d-flex flex-row">
                        <div className="col-8 mt-5">
                          <h5 className="px-3 pt-2">
                            SPECIAL ORDER: NO REFUNDS AND NO EXCHANGE
                          </h5>
                          <ul className="mb-0">
                            <li>
                              Balanced Tires and Performed Alignment on Customer
                              Request - Initial: ___________
                            </li>
                            <li>
                              TIRE PRESSURE SET ACCORDING TO FACTORY
                              SPECIFICATIONS
                            </li>

                            <li>
                              New or Used Tires Shown to Customer Before
                              Installation
                            </li>
                            <li>
                              Torque All Lugnuts by Factory Specifications
                            </li>
                            {/* <li>
                             {" "}
                             Vehicle Was Not Aligned Properly At Time Of
                             Installment
                           </li>
                           <li>
                             Installed on Customer Request : Initial ___________
                           </li> */}
                          </ul>
                          <Row>
                            <Col xs="6">
                              <ul>
                                {/* <li> No Warranty on Low Profile Tire</li> */}
                                <li> New Valve Stem Installed</li>
                                <li>No Warranty ___________</li>
                                <li>No Road Hazardous Warranty</li>
                              </ul>
                            </Col>
                            <Col xs="6">
                              <ul>
                                <li>No Warranty on Used Tire</li>

                                <li>
                                  Customer Shown DOT Date of Manufacturing
                                </li>
                                <li>
                                  {" "}
                                  Rotation recommended after every 5000 miles
                                </li>
                                {/* <li>Checked in water</li>
                               <li>Flat Repair</li> */}
                              </ul>
                            </Col>
                          </Row>
                        </div>
                        <div className="col-4   border">
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6
                                style={{ fontSize: "17px" }}
                                className="text-muted  py-1 mb-0"
                              >
                                Sub Total Taxable
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6
                                style={{ fontSize: "17px" }}
                                className="text-muted float-right py-1 mb-0"
                              >
                                {CURRENCY_DESC}{" "}
                                {subTotalTaxAble
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6
                                style={{ fontSize: "17px" }}
                                className="text-muted  py-1 mb-0"
                              >
                                SALES TAX ({salesTax}%)
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6
                                style={{ fontSize: "17px" }}
                                className="text-muted float-right py-1 mb-0"
                              >
                                {CURRENCY_DESC}{" "}
                                {saleTaxAmount
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6
                                style={{ fontSize: "17px" }}
                                className="text-muted  py-1 mb-0"
                              >
                                Sub Total Services
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6
                                style={{ fontSize: "17px" }}
                                className="text-muted float-right py-1 mb-0"
                              >
                                {CURRENCY_DESC}{" "}
                                {subTotalNonTaxAble
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </h6>
                            </Col>
                          </Row>
                          {/* <Row>
                           <Col xs="6" className="border">
                             {" "}
                             <h6 className="text-muted  py-1 mb-0">Net Total</h6>
                           </Col>
                           <Col xs="6" className="border">
                             <h6 className="text-muted float-right py-1 mb-0">
                               {CURRENCY_DESC}{" "}
                               {(
                                 sub_Amount_total_items -
                                 disTotalItems +
                                 saleTaxAmount 
                               )
                                 ?.toFixed(2)
                                 .toString()
                                 .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                             </h6>
                           </Col>
                         </Row> */}
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6
                                style={{ fontSize: "17px" }}
                                className=" py-1  text-muted mb-0"
                              >
                                LABOUR
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6
                                style={{ fontSize: "17px" }}
                                className="text-muted float-right py-1 mb-0"
                              >
                                {" "}
                                {CURRENCY_DESC} {this.dueFormator(labour)}
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6
                                style={{ fontSize: "17px" }}
                                className="text-muted  py-1 mb-0"
                              >
                                TIRE FEE ({CURRENCY_DESC} 1.75)
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6
                                style={{ fontSize: "17px" }}
                                className="text-muted float-right py-1 mb-0"
                              >
                                {" "}
                                {CURRENCY_DESC} {this.dueFormator(tireFee)}
                                {/* {tireFee
                                 ?.toString()
                                 .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6
                                style={{ fontSize: "17px" }}
                                className="text-muted  py-1 mb-0"
                              >
                                TIRE DISPOSSAL
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6
                                style={{ fontSize: "17px" }}
                                className="text-muted float-right py-1 mb-0"
                              >
                                {" "}
                                {CURRENCY_DESC}{" "}
                                {/* {this.convertToCurrency(TireDispossal)} */}
                                {Number(
                                  TireDispossal?.toString()?.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                )?.toFixed(2)}
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6
                                style={{ fontSize: "17px" }}
                                className="text-muted  py-1 mb-0"
                              >
                                FET
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6
                                style={{ fontSize: "17px" }}
                                className="text-muted float-right py-1 mb-0"
                              >
                                {" "}
                                {CURRENCY_DESC}{" "}
                                {/*{this.convertToCurrency(total_fet)} */}
                                {total_fet
                                  ?.toFixed(2)
                                  ?.toString()
                                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </h6>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6
                                style={{ fontSize: "17px" }}
                                className="text-muted  py-1 mb-0"
                              >
                                SUB TOTAL
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6
                                style={{ fontSize: "17px" }}
                                className="text-muted float-right py-1 mb-0"
                              >
                                {" "}
                                {CURRENCY_DESC}{" "}
                                {/*{this.convertToCurrency(total_fet)} */}
                                {total_Amount
                                  ?.toFixed(2)
                                  ?.toString()
                                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6
                                style={{ fontSize: "17px" }}
                                className="text-muted  py-1 mb-0"
                              >
                                Card Fee ({ccFeePercentage}%)
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6
                                style={{ fontSize: "17px" }}
                                className="text-muted float-right py-1 mb-0"
                              >
                                {" "}
                                {CURRENCY_DESC}{" "}
                                {/*{this.convertToCurrency(total_fet)} */}
                                {CC_Fee?.toFixed(2)
                                  ?.toString()
                                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6
                                style={{ fontSize: "17px" }}
                                className="text-muted  py-1 mb-0"
                              >
                                TOTAL
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6
                                style={{ fontSize: "17px" }}
                                className="text-muted float-right py-1 mb-0"
                              >
                                {CURRENCY_DESC}{" "}
                                {(total_Amount + CC_Fee)
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6
                                style={{ fontSize: "17px" }}
                                className="text-muted  py-1 mb-0"
                              >
                                DEPOSIT
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6
                                style={{ fontSize: "17px" }}
                                className="text-muted float-right py-1 mb-0"
                              >
                                {" "}
                                {CURRENCY_DESC}{" "}
                                {paid_amt
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6
                                style={{ fontSize: "17px" }}
                                className="text-muted  py-1 mb-0"
                              >
                                BALANCE DUE
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6
                                style={{ fontSize: "17px" }}
                                className="text-muted float-right py-1 mb-0"
                              >
                                {CURRENCY_DESC}{" "}
                                {(total_Amount + CC_Fee - paid_amt)
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </h6>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 pt-1">
                          <br></br>
                          <h5>
                            CUSTOMERS: PLEASE NOTE: NOT RESPONSIBLE FOR BROKEN
                            OR LOST HUB CAPS{" "}
                          </h5>
                          <p>
                            Company does not provide any kind of warranty on
                            used tires and no warranty on shaved white wall
                            TOTAL tires. The company will not be responsible if
                            anything happens to you or your vehicle due to used
                            tires. All warranties on the products sold hereby
                            are those made by the manufacturer. The seller
                            (above-named dealership) hereby expressly disclaims
                            all warranties, either expressed or implied,
                            including any implied DEPOSIT warranty of
                            merchantability or fitness for purpose, and neither
                            assumes nor authorizes any other person to assume
                            for it any LIABILITY in connection with the safety
                            of said products. Buyer shall not be entitled
                            BALANCE to recover from seller any consequential
                            damages to property, damage for a time, loss of
                            profit or income, DUE, or any other incidental
                            damage. No Warranty on Aftermarket Wheels.{" "}
                            {dynamicNamePrinting} will not provide any kind of
                            Warranty at all on economy tires and wheels.{" "}
                            {dynamicNamePrinting} will not be responsible for
                            any damages to car prior to service.
                          </p>
                          <p>
                            Customer has inspected the car prior to leaving -
                            Initial: _____________
                            <br />
                            I have read all information above carefully. The
                            company is not liable for any damages incurred after
                            vehicle has left the shop - Initial:_____________.
                            <br />
                            NO RETURNS ON ANY ITEMS SOLD BY{" "}
                            {dynamicNamePrinting?.toUpperCase()}. NO CASH REFUND
                            CUSTOMER <br />
                            SIGNATURE
                            __________________________________________________
                            Company will not be responsible for any item left
                            over 3 days.
                          </p>
                        </div>
                      </div>
                    </Container>
                    {/* <div class="d-lg-none">hide on screens wider than lg</div> ya cti pa  */}
                    {/* <div class="d-none d-lg-block">hide on screens smaller than lg</div> ya bari screen pa  */}
                  </div>
                </Col>
              ) : (
                // mobile screen print starting from here
                <Col xs="12">
                  <div id="pdfContent">
                    <br></br>
                    <br></br>
                    <Container>
                      <Row className="my-2">
                        <Col xs="4">
                          <img
                            src={FolsomLogo}
                            alt="FOLSOM TIRE & WHEEL"
                            // className=" w-50 h-50"
                            width={160}
                            height={60}
                          />

                          {/*
                        <Row>
                           <Col md="4">
                            <span className="PR_invoice_img">
                              {" "}
                              <ReactRoundedImage
                                className="ReactRoundedImage"
                                image={companySym}
                                roundedColor="#321124"
                                imageWidth="120"
                                imageHeight="120"
                                roundedSize="4"
                              />
                            </span>
                          
                            {/* <h2
                              className="text-center text-danger"
                              style={{ fontFamily: "Algerian" }}
                            >
                              {companyName} 
                              FOLSOM
                            </h2>
                            <h6>TIRE & WHEELS</h6>  
                          </Col> 
                        </Row>
                           */}
                        </Col>

                        <Col xs="8">
                          <div style={{ textAlign: "end" }}>
                            <button
                              className="btn btn-primary mx-2 btn-sm"
                              id="printPageButton"
                              onClick={() => {
                                window.print();
                              }}
                            >
                              Print to PDF!
                            </button>
                            <button
                              className="btn btn-primary m-1  btn-sm"
                              id="printPageButton"
                              onClick={() => {
                                this.goBackToInvoice();
                              }}
                            >
                              Back
                            </button>
                          </div>
                          <h3 className="text-right pr-2 my-0">
                            {invBookId === 1 ? "INVOICE: " : "Estimate: "}
                            <span className="text-muted"> {invoiceNo}</span>
                          </h3>
                          <hr className="border my-0  w-50 float-right " />
                          <br />
                          <h6 className="text-right text-muted">
                            {complete_address} <br />
                            Tel: {company_tel_no} • Fax: {company_fax_no} <br />
                            {company_email}
                            {/* 9565 Folsom Blvd., Sacramento, CA 95827 <br />
                            Tel: (916)706-1745 • Fax: (916) 538-6346 <br />
                            folsomtireandwheels@hotmail.com {} */}
                          </h6>
                        </Col>
                      </Row>
                    </Container>

                    <Row className="my-1">
                      <Container className=" px-4 mx-3">
                        <Row>
                          <Col xs="6" lg="5" className="border">
                            {" "}
                            <h5>
                              Name :{" "}
                              <span className="h5 text-muted">
                                {clientName}
                              </span>
                            </h5>
                          </Col>
                          <Col xs="6" lg="4" className="border">
                            {" "}
                            <h5>
                              Phone :{" "}
                              <span className="h5 text-muted">{phone}</span>
                            </h5>{" "}
                          </Col>
                          <Col md="3" className="border">
                            <h5>
                              Date :{" "}
                              <span className="h5 text-muted">
                                {moment(issueDate).format("MM DD YYYY")}
                              </span>
                            </h5>
                            {/* <p style={{ textAlign: "right" }}>
                          Due Date : {dueDate}
                        </p> */}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="9" lg="9" md="9" className="border">
                            <h5>
                              Address :{" "}
                              <span className="h5 text-muted">{address}</span>
                            </h5>
                          </Col>
                          <Col xs="3" lg="3" md="3" className="border">
                            <h5>
                              Type :{" "}
                              <span className="h5 text-muted">
                                {saleCategory}
                              </span>
                            </h5>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6" lg="4" md="4" className="border">
                            <h5>
                              City :{" "}
                              <span className="h5 text-muted">{city}</span>
                            </h5>
                          </Col>
                          <Col xs="6" lg="3" md="3" className="border">
                            <h5>
                              State :{" "}
                              <span className="h5 text-muted">{state}</span>
                            </h5>
                          </Col>
                          <Col xs="6" lg="2" md="2" className="border">
                            <h5>
                              Zip :{" "}
                              <span className="h5 text-muted">{zipCode}</span>
                            </h5>
                          </Col>
                          <Col xs="6" lg="3" md="3" className="border">
                            <h5>
                              Model :{" "}
                              <span className="h5 text-muted">{viModel}</span>
                            </h5>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="4" lg="5" md="5" className="border">
                            <h5>
                              Vehicle Id No :{" "}
                              <span className="h5 text-muted">
                                {vehicleIdNo === "null"
                                  ? ""
                                  : vehicleIdNo || ""}
                              </span>
                            </h5>
                          </Col>
                          <Col md="4" xs="4" lg="4" className="border">
                            <h5>
                              Mileage :{" "}
                              <span className="h5 text-muted">{viMileage}</span>
                            </h5>
                          </Col>
                          <Col xs="4" lg="3" md="3" className="border">
                            <h5>
                              License Plate No :{" "}
                              <span className="text-muted"> {licenseNo}</span>
                            </h5>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="9" className="border py-2">
                            <Row>
                              <div className="col-6 col-md-6">
                                {" "}
                                <FormGroup check>
                                  <Input type="checkbox" />{" "}
                                  <Label check>CASH</Label>
                                </FormGroup>
                              </div>
                              <div className="col-6 col-md-6">
                                <FormGroup check>
                                  <Input type="checkbox" />{" "}
                                  <Label check>CHARGE</Label>
                                </FormGroup>
                              </div>
                              <div className="col-6 col-md-6">
                                <FormGroup check>
                                  <Input type="checkbox" />{" "}
                                  <Label check>CHECK</Label>
                                </FormGroup>
                              </div>
                              <div className="col-6 col-md-6">
                                <FormGroup check>
                                  <Input type="checkbox" />{" "}
                                  <Label check>FINANCE</Label>
                                </FormGroup>
                              </div>
                            </Row>
                          </Col>
                          <Col sx="6" lg="3" className="border">
                            <h5>
                              Year-Make :{" "}
                              <span className="h5 text-muted">
                                {viYearMake}
                              </span>
                            </h5>
                          </Col>
                        </Row>
                        {remarks !== "undefined" && remarks && (
                          <Row>
                            <Col md="12" className="border">
                              <h6>
                                {/* Notes visible to the client on the invoice:{" "} */}
                                Remarks :
                                <span
                                  className="text-muted"
                                  style={{
                                    fontSize: "12px",
                                    fontStyle: "italic",
                                  }}
                                >
                                  {" "}
                                  {remarks}
                                </span>
                              </h6>
                            </Col>
                          </Row>
                        )}
                      </Container>
                    </Row>
                    <Row className="my-1">
                      <Container
                        className="border px-3 mx-4"
                        style={{ height: "28rem" }}
                      >
                        <div className="row">
                          {/* <Col md="1" className="border">
                          <h4>Code </h4>
                        </Col> */}
                          <div className=" col-2 col-lg-1 border text-right">
                            <h6 style={{}}>Qty</h6>
                          </div>
                          <div md="4" className="col-2 border">
                            <h6>Part #</h6>{" "}
                          </div>
                          <div md="4" className="col-2 border">
                            <h6>Item</h6>{" "}
                          </div>
                          <div
                            md="4"
                            className="col-3 col-lg-4 d-none d-lg-block border"
                          >
                            <h6 style={{}}>Description</h6>
                          </div>
                          <div className=" col-2 col-lg-1  border text-right">
                            <h6 style={{}}>Price</h6>
                          </div>
                          <div
                            md="1"
                            className=" col-2 col-lg-1 border text-right px-1"
                          >
                            <h6>Discount</h6>
                          </div>
                          <div md="1" className=" col-2 col-lg-1 border">
                            <h6 className="text-right">Net Total</h6>
                          </div>
                        </div>
                        <div className=" px-3 mx-n3">
                          {this.state.res_det?.map((itm, index) => {
                            return (
                              <div
                                className="d-flex flex-row border-bottom "
                                key={index}
                              >
                                {/* <Col md="1" className="border text-center">
                                <p>{itm.ITEM_CODE} </p>
                              </Col> */}
                                <Col xs="2" lg="1">
                                  <Row>
                                    {/* <Col xs= md="6" className="text-left">
                                     <p className="mb-0   ">
                                       
                                      {!itm.service_item_yn && "*"}{" "}
                                    </p> 
                                  </Col> */}
                                    <Col xs="12" className="text-right">
                                      <p className="mb-0   ">
                                        {itm.PRIMARY_QTY.toString()}
                                      </p>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md="2" className=" ">
                                  <p className="mb-0">{itm.ITEM_CODE}</p>
                                </Col>
                                <Col md="2" className=" ">
                                  <p className="mb-0">
                                    {/* {` ${itm.ITEM_CODE} ${itm.Item_Desc_Lov}  `} */}
                                    {itm.Item_Desc_Lov}
                                  </p>
                                  {/* <span>{itm.ITEM_DESC || ""}</span>{" "} */}
                                </Col>
                                <Col
                                  sx="3"
                                  lg="4"
                                  className="d-none d-lg-block"
                                >
                                  <p
                                    className="mb-0"
                                    style={{
                                      fontSize: "12px",
                                      fontStyle: "italic",
                                    }}
                                  >
                                    {itm.ITEM_DESC}
                                  </p>
                                </Col>
                                <Col xs="2" lg="1" className="px-1">
                                  <p className="mb-0 text-right">
                                    {CURRENCY_DESC}{" "}
                                    {(itm.TC_AMOUNT / itm.PRIMARY_QTY)
                                      .toFixed(2)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </p>
                                </Col>
                                <Col xs="2" lg="1" className="px-1">
                                  <p className="mb-0 text-right">
                                    {CURRENCY_DESC}{" "}
                                    {itm.TC_DISCOUNT.toFixed(2)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </p>
                                </Col>
                                <Col xs="2" lg="1" className="px-1">
                                  <p className="mb-0 text-right">
                                    {CURRENCY_DESC}{" "}
                                    {(itm.TC_AMOUNT - itm.TC_DISCOUNT)
                                      .toFixed(2)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </p>
                                </Col>
                              </div>
                            );
                          })}
                          <div className="d-flex flex-row  ">
                            <div className="col-3 col-md-9">
                              {/* <p className="mb-0   ">
                              Note : *Star marked items are taxable
                            </p> */}
                            </div>
                            <div className="col-3 col-md-1 px-1 border">
                              <p className="mb-0 text-right">Sub-Total</p>
                            </div>
                            <div className="col-3 col-md-1 px-1 border">
                              <p className="mb-0 text-right">
                                {" "}
                                {CURRENCY_DESC}{" "}
                                {disTotalItems
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </p>
                            </div>
                            <div className=" col-3  col-md-1 px-1 border">
                              <p className="mb-0 text-right">
                                {" "}
                                {CURRENCY_DESC}{" "}
                                {sub_Amount_total_items
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </p>
                            </div>
                          </div>
                          {/* <Row className=" ">
                          <Col md="10" className=""></Col>
                          <Col md="1" className="px-1 border">
                            <p className="mb-0 text-right">Discount</p>
                          </Col>
                          <Col md="1" className="px-1 border">
                            <p className="mb-0 text-right">
                              {" "}
                              {CURRENCY_DESC}{" "}
                              {disTotalItems
                                ?.toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </p>
                          </Col>
                        </Row>
                        <Row className=" ">
                          <Col md="10" className="">
                            <p
                              className="mb-0"
                              style={{
                                fontSize: "12px",
                                fontStyle: "italic",
                              }}
                            ></p>
                          </Col>
                          <Col md="1" className="px-1 border">
                            <p className="mb-0 text-right">Net Total</p>
                          </Col>
                          <Col md="1" className="px-1 border">
                            <p className="mb-0 text-right">
                              {" "}
                              {CURRENCY_DESC}{" "}
                              {(sub_Amount_total_items - disTotalItems)
                                ?.toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </p>
                          </Col>
                        </Row> */}
                        </div>
                      </Container>
                    </Row>
                    <Container>
                      <div className="d-flex flex-row flex-wrap  ">
                        <div className="col-md-4 d-lg-none border">
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6 className="text-muted  py-1 mb-0">
                                Sub Total Taxable
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6 className="text-muted float-right py-1 mb-0">
                                {CURRENCY_DESC}{" "}
                                {subTotalTaxAble
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6 className="text-muted  py-1 mb-0">
                                SALES TAX ({salesTax}%)
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6 className="text-muted float-right py-1 mb-0">
                                {CURRENCY_DESC}{" "}
                                {saleTaxAmount
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6 className="text-muted  py-1 mb-0">
                                Sub Total Services
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6 className="text-muted float-right py-1 mb-0">
                                {CURRENCY_DESC}{" "}
                                {subTotalNonTaxAble
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </h6>
                            </Col>
                          </Row>
                          {/* <Row>
                          <Col xs="6" className="border">
                            {" "}
                            <h6 className="text-muted  py-1 mb-0">Net Total</h6>
                          </Col>
                          <Col xs="6" className="border">
                            <h6 className="text-muted float-right py-1 mb-0">
                              {CURRENCY_DESC}{" "}
                              {(
                                sub_Amount_total_items -
                                disTotalItems +
                                saleTaxAmount 
                              )
                                ?.toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </h6>
                          </Col>
                        </Row> */}
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6 className=" py-1  text-muted mb-0">
                                LABOUR:
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6 className="text-muted float-right py-1 mb-0">
                                {" "}
                                {CURRENCY_DESC} {this.dueFormator(labour)}
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6 className="text-muted  py-1 mb-0">
                                TIRE FEE: ({CURRENCY_DESC} 1.75)
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6 className="text-muted float-right py-1 mb-0">
                                {" "}
                                {CURRENCY_DESC} {this.dueFormator(tireFee)}
                                {/* {tireFee
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6 className="text-muted  py-1 mb-0">
                                TIRE DISPOSSAL
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6 className="text-muted float-right py-1 mb-0">
                                {" "}
                                {CURRENCY_DESC}{" "}
                                {/* {this.convertToCurrency(TireDispossal)} */}
                                {Number(
                                  TireDispossal?.toString()?.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                )?.toFixed(2)}
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6 className="text-muted  py-1 mb-0">FET</h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6 className="text-muted float-right py-1 mb-0">
                                {" "}
                                {CURRENCY_DESC}{" "}
                                {/*{this.convertToCurrency(total_fet)} */}
                                {total_fet
                                  ?.toFixed(2)
                                  ?.toString()
                                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6 className="text-muted  py-1 mb-0">
                                Card Fee
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6 className="text-muted float-right py-1 mb-0">
                                {" "}
                                {CURRENCY_DESC}{" "}
                                {/*{this.convertToCurrency(total_fet)} */}
                                {CC_Fee?.toFixed(2)
                                  ?.toString()
                                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6 className="text-muted  py-1 mb-0">TOTAL</h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6 className="text-muted float-right py-1 mb-0">
                                {CURRENCY_DESC}{" "}
                                {total_Amount
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6 className="text-muted  py-1 mb-0">DEPOSIT</h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6 className="text-muted float-right py-1 mb-0"></h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6 className="text-muted  py-1 mb-0">
                                BALANCE DUE
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6 className="text-muted float-right py-1 mb-0"></h6>
                            </Col>
                          </Row>
                        </div>
                        <div className="col-md-8 border">
                          <h5 className="px-3 pt-2">
                            SPECIAL ORDER: NO REFUNDS AND NO EXCHANGE2
                          </h5>
                          <ul className="mb-0">
                            <li>
                              Balanced Tires and Performed Alignment on Customer
                              Request - Initial: ___________
                            </li>
                            <li>
                              TIRE PRESSURE SET ACCORDING TO FACTORY
                              SPECIFICATIONS
                            </li>

                            <li>
                              New or Used Tires Shown to Customer Before
                              Installation
                            </li>
                            <li>
                              Torque All Lugnuts by Factory Specifications
                            </li>
                            {/* <li>
                            {" "}
                            Vehicle Was Not Aligned Properly At Time Of
                            Installment
                          </li>
                          <li>
                            Installed on Customer Request : Initial ___________
                          </li> */}
                          </ul>
                          <Row>
                            <Col xs="6">
                              <ul>
                                {/* <li> No Warranty on Low Profile Tire</li> */}
                                <li> New Valve Stem Installed</li>
                                <li>No Warranty ___________</li>
                                <li>No Road Hazardous Warranty</li>
                              </ul>
                            </Col>
                            <Col xs="6">
                              <ul>
                                <li>No Warranty on Used Tire</li>

                                <li>
                                  Customer Shown DOT Date of Manufacturing
                                </li>
                                <li>
                                  {" "}
                                  Rotation recommended after every 5000 miles
                                </li>
                                {/* <li>Checked in water</li>
                              <li>Flat Repair</li> */}
                              </ul>
                            </Col>
                          </Row>
                        </div>
                        <div className="col-md-4 d-none d-lg-block border">
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6 className="text-muted  py-1 mb-0">
                                Sub Total Taxable
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6 className="text-muted float-right py-1 mb-0">
                                {CURRENCY_DESC}{" "}
                                {subTotalTaxAble
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6 className="text-muted  py-1 mb-0">
                                SALES TAX ({salesTax}%)
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6 className="text-muted float-right py-1 mb-0">
                                {CURRENCY_DESC}{" "}
                                {saleTaxAmount
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6 className="text-muted  py-1 mb-0">
                                Sub Total Services
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6 className="text-muted float-right py-1 mb-0">
                                {CURRENCY_DESC}{" "}
                                {subTotalNonTaxAble
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </h6>
                            </Col>
                          </Row>
                          {/* <Row>
                          <Col xs="6" className="border">
                            {" "}
                            <h6 className="text-muted  py-1 mb-0">Net Total</h6>
                          </Col>
                          <Col xs="6" className="border">
                            <h6 className="text-muted float-right py-1 mb-0">
                              {CURRENCY_DESC}{" "}
                              {(
                                sub_Amount_total_items -
                                disTotalItems +
                                saleTaxAmount 
                              )
                                ?.toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </h6>
                          </Col>
                        </Row> */}
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6 className=" py-1  text-muted mb-0">
                                LABOUR:
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6 className="text-muted float-right py-1 mb-0">
                                {" "}
                                {CURRENCY_DESC} {this.dueFormator(labour)}
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6 className="text-muted  py-1 mb-0">
                                TIRE FEE: ({CURRENCY_DESC} 1.75)
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6 className="text-muted float-right py-1 mb-0">
                                {" "}
                                {CURRENCY_DESC} {this.dueFormator(tireFee)}
                                {/* {tireFee
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6 className="text-muted  py-1 mb-0">
                                TIRE DISPOSSAL
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6 className="text-muted float-right py-1 mb-0">
                                {" "}
                                {CURRENCY_DESC}{" "}
                                {/* {this.convertToCurrency(TireDispossal)} */}
                                {Number(
                                  TireDispossal?.toString()?.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                )?.toFixed(2)}
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6 className="text-muted  py-1 mb-0">FET</h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6 className="text-muted float-right py-1 mb-0">
                                {" "}
                                {CURRENCY_DESC}{" "}
                                {/*{this.convertToCurrency(total_fet)} */}
                                {total_fet
                                  ?.toFixed(2)
                                  ?.toString()
                                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6 className="text-muted  py-1 mb-0">TOTAL</h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6 className="text-muted float-right py-1 mb-0">
                                {CURRENCY_DESC}{" "}
                                {total_Amount
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6 className="text-muted  py-1 mb-0">DEPOSIT</h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6 className="text-muted float-right py-1 mb-0"></h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6" className="border">
                              {" "}
                              <h6 className="text-muted  py-1 mb-0">
                                BALANCE DUE
                              </h6>
                            </Col>
                            <Col xs="6" className="border">
                              <h6 className="text-muted float-right py-1 mb-0"></h6>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 pt-1">
                          <br></br>
                          <h5>
                            CUSTOMERS: PLEASE NOTE: NOT RESPONSIBLE FOR BROKEN
                            OR LOST HUB CAPS{" "}
                          </h5>
                          <p>
                            Company does not provide any kind of warranty on
                            used tires and no warranty on shaved white wall
                            TOTAL tires. The company will not be responsible if
                            anything happens to you or your vehicle due to used
                            tires. All warranties on the products sold hereby
                            are those made by the manufacturer. The seller
                            (above-named dealership) hereby expressly disclaims
                            all warranties, either expressed or implied,
                            including any implied DEPOSIT warranty of
                            merchantability or fitness for purpose, and neither
                            assumes nor authorizes any other person to assume
                            for it any LIABILITY in connection with the safety
                            of said products. Buyer shall not be entitled
                            BALANCE to recover from seller any consequential
                            damages to property, damage for a time, loss of
                            profit or income, DUE, or any other incidental
                            damage. No Warranty on Aftermarket Wheels.{" "}
                            {dynamicNamePrinting} will not provide any kind of
                            Warranty at all on economy tires and wheels.{" "}
                            {dynamicNamePrinting} will not be responsible for
                            any damages to car prior to service.
                          </p>
                          <p>
                            Customer has inspected the car prior to leaving -
                            Initial: _____________
                            <br />
                            I have read all information above carefully. The
                            company is not liable for any damages incurred after
                            vehicle has left the shop - Initial:_____________.
                            <br />
                            NO RETURNS ON ANY ITEMS SOLD BY{" "}
                            {dynamicNamePrinting?.toUpperCase()}. NO CASH REFUND
                            CUSTOMER <br />
                            SIGNATURE
                            __________________________________________________
                            Company will not be responsible for any item left
                            over 3 days.
                          </p>
                        </div>
                      </div>
                    </Container>
                    {/* <div class="d-lg-none">hide on screens wider than lg</div> ya cti pa  */}
                    {/* <div class="d-none d-lg-block">hide on screens smaller than lg</div> ya bari screen pa  */}
                  </div>
                </Col>
              )}
              {console.log("this.state.isMobile", isMobile)}
            </Row>
          ) : (
            <Row className="app-body-Invoice">
              <Col xs="12">
                <Row>
                  <Col xs="3"> </Col>
                  <Col xs="6">
                    {" "}
                    <h2 className="text-center">
                      <u>{dynamicInvMessage}</u>
                    </h2>{" "}
                  </Col>
                  <Col xs="3">
                    <div style={{ textAlign: "end" }}>
                      <button
                        className="btn btn-primary mx-2"
                        id="printPageButton"
                        onClick={() => {
                          window.print();
                        }}
                      >
                        Print to PDF!
                      </button>
                      <button
                        className="btn btn-primary m-1"
                        id="printPageButton"
                        onClick={() => {
                          this.goBackToInvoice();
                        }}
                      >
                        Back
                      </button>
                    </div>{" "}
                  </Col>
                </Row>
                <br></br>
                <Row className="">
                  <Col xs="12" className="">
                    <h4>{`${
                      specificMsg ? specificMsg : dynamicInvMessage
                    } No: ${invoiceNo}`}</h4>
                  </Col>

                  {poNumber && (
                    <Col xs="12" className="">
                      <h4>{`PO Number: ${poNumber}`}</h4>
                    </Col>
                  )}
                </Row>

                <hr
                  style={{
                    border: "1px solid Light gray",
                  }}
                />

                <Row className="">
                  <Col xs="6" className="PR_invoice_img">
                    {" "}
                    <ReactRoundedImage
                      className="ReactRoundedImage"
                      image={companySym}
                      roundedColor="#321124"
                      imageWidth="50"
                      imageHeight="45"
                      roundedSize="4"
                    />
                    <h1>{companyName}</h1>
                  </Col>

                  <Col xs="6" className="">
                    <h4 style={{ textAlign: "right" }}>
                      Issue Date : {issueDate}
                    </h4>
                    <h4 style={{ fontWeight: "bold", textAlign: "right" }}>
                      Due Date : {dueDate}
                    </h4>
                  </Col>
                </Row>

                <hr
                  style={{
                    border: "1px solid Light gray",
                  }}
                />
                <Row className="">
                  <Col xs="6" className="">
                    <h2>{clientName}</h2>
                    <h3> address: {address}&emsp;&emsp;</h3>
                    <h3>Phone: {phone}&emsp;</h3>
                    <h3> Mail: {email}</h3>
                    <h3> Reg #: {company_no}</h3>
                  </Col>
                </Row>
                <hr
                  style={{
                    border: "1px solid Light gray",
                  }}
                />
                <Row xs="12">
                  <Col xs="12" className="">
                    <table>
                      <tr>
                        {Item_Desc_Lov ? (
                          <>
                            {" "}
                            <th>Item Code...</th> <th>Description</th>{" "}
                          </>
                        ) : (
                          <th>Description</th>
                        )}
                        <th>Qty</th>
                        <th>Unit</th>
                        <th>Rate</th>
                        <th className="text-right">Amount</th>
                      </tr>
                      {this.state.res_det?.map((itm, index) => {
                        return (
                          <>
                            <tr key={index}>
                              {itm.Item_Desc_Lov ? (
                                <>
                                  {" "}
                                  <td>{itm.ITEM_CODE}</td>{" "}
                                  <td>{itm.Item_Desc_Lov}</td>{" "}
                                </>
                              ) : (
                                <td>{itm.Expense_Desc}</td>
                              )}

                              <td>
                                {itm.PRIMARY_QTY &&
                                itm.PRIMARY_QTY != "undefined"
                                  ? itm.PRIMARY_QTY.toString()
                                  : ""}
                              </td>
                              <td>{itm.ITEM_DESC}</td>

                              <td>
                                {CURRENCY_DESC}{" "}
                                {(itm.TC_AMOUNT / itm.PRIMARY_QTY)
                                  .toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <td className="text-right">
                                {CURRENCY_DESC}{" "}
                                {itm.TC_AMOUNT.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </table>
                  </Col>
                  {disTotalItems > 0 && (
                    <Col xs="12">
                      <h5 className="text-muted float-right pt-2 ">
                        Discount: {CURRENCY_DESC}{" "}
                        {disTotalItems
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </h5>
                    </Col>
                  )}
                  <Col xs="12" className="">
                    <hr
                      style={{
                        border: "1px solid Light gray",
                      }}
                    />
                  </Col>

                  <Col xs="12" className="">
                    <h4 style={{ textAlign: "right" }}>
                      Subtotal : {CURRENCY_DESC}{" "}
                      {sub_Amount_total_items
                        ?.toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </h4>
                    <hr
                      style={{
                        border: "1px solid Light gray",
                      }}
                    />
                  </Col>

                  {saleTaxAmount ? (
                    <Col xs="12" className="">
                      <h4 style={{ textAlign: "right" }}>
                        Sale Tax ({salesTax}%): {CURRENCY_DESC}{" "}
                        {saleTaxAmount
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </h4>
                      <hr
                        style={{
                          border: "1px solid Light gray",
                        }}
                      />
                    </Col>
                  ) : null}

                  <Col xs="12" className="">
                    <h3 className="text-right">
                      TOTAL: {CURRENCY_DESC}{" "}
                      {total_Amount
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </h3>
                  </Col>
                  <br></br>
                  <br></br>
                  <br></br>
                  <Col xs="10" className="">
                    <h4
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Payment Terms: Invoice to be settled by {dueDate}
                    </h4>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <Row className="app-body-Invoice">
            <Col>
              <div
                className="col-md-12"
                style={{ width: "1100px", margin: "0 auto" }}
              >
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <h2 style={{ fontWeight: "bold" }}>
                        <Redirect to="/OverviewInvoice" />
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
  }
}

export default PrintView;
